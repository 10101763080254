export async function checkEventEnd(date: any) {

    const { API_URL } = useRuntimeConfig().public
    let checkEventEnd = false;

    try {

        const response: any = await $fetch(`${API_URL}/datetime.php`)
        checkEventEnd = (response.date > date) ? true : false
        return checkEventEnd

    } catch (error) {
        console.error('Fehler beim Request auf aktuelles Datum')
    }



}