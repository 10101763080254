<script setup>
const { t: $t } = useI18n();
const localePath = useLocalePath();

const toRoute = ref();
const buttonLabel = ref();

onMounted(() => {
    const { enabledGallery } = useRuntimeConfig().public;
    if (enabledGallery) {
        buttonLabel.value = $t('buttons.toGallery');
        toRoute.value = 'gallery';
    } else {
        buttonLabel.value = $t('buttons.toRegistration');
        toRoute.value = 'registration';
    }
})


const onSubmit = async () => {
    //await navigateTo(localePath(toRoute.value)); // keinen slash vor routen-namen!
    await navigateTo(localePath({ name: toRoute.value, hash: '#form' }));
}

</script>

<template>
    <div>
        <article class="prose">
            <DisplayText text="index-without-login" />
        </article>
        <div class="flex mt-4">
            <Button :label="buttonLabel" @click="onSubmit" class="flex-grow-1 md:flex-grow-0" />
        </div>
    </div>
</template>

<style scoped></style>
