export async function checkContingent(defaultLocale: any) {
    const { API_URL } = useRuntimeConfig().public

    try {
        const contingent: any = await $fetch(`${API_URL}/contingent.php`);

        // check contingent deactivated
        if ((contingent) && (contingent.status === 201)) {
            return
        }

        // check contingent deactivated
        if ((contingent) && (contingent.status === 204)) {
            return
        }

        // check contingent activated
        if ((contingent) && (contingent.status === 200)) {

            const total = toRaw(contingent.total);
            if (total) {
                return true
            } else {
                const localesAll = toRaw(contingent.locales_all);
                const localesItem = localesAll[defaultLocale];
                if (localesItem) {
                    return true
                } else {
                    return false
                }
            }
        } else {
            console.error('Fehler bei Zugriff auf Kontingent-API')
        }
    } catch (error) {
        console.error(error)
    }
















    // checkEventEnd = () ? true : false;

}