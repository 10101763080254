import type { User } from "~/types";

export default function useHash() {
    return {
        checkHashes,
        createHash
    };
};

function splitStringToArray(str: string) {
    return str.split('').reduce((acc, char) => {
        if (char === ',') {
            acc.push('');
        } else {
            acc[acc.length - 1] += char;
        }
        return acc;
    }, ['']);
}

async function createHash(stringToHash: string) {
    const utf8 = new TextEncoder().encode(stringToHash);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
    return hashHex;
}

async function checkHashes(user: User) {
    const { loginHashes } = useRuntimeConfig().public;

    const userData = user.username + user.password;
    const generatedHashFromUserData = await createHash(userData);

    const loginHashesArray = splitStringToArray(loginHashes);
    const isInLoginHashesArray = loginHashesArray.includes(generatedHashFromUserData);

    return isInLoginHashesArray;
}