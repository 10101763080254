<script setup>
const { t: $t } = useI18n();
const hostEventAddress = ref($t('host.eventAddress'));
</script>

<template>
    <div class="locationbox__columnwrapper">
        <div class="locationbox__column locationbox__column--textbox">
            <ul>
                <li class="locationbox__listitem locationbox__listitem--location">
                    <span class="locationbox__listicon"><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z">
                            </path>
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z">
                            </path>
                        </svg>
                    </span>
                    <span>
                        <span v-html="hostEventAddress"></span><br><br>
                        <a class="link" target="_blank" rel="noopener noreferrer"
                            href="https://maps.app.goo.gl/SMezwESJ1EVL75BMA">{{ $t('host.eventViewLocation') }}</a>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.link {
    color: #c7000d;
    text-decoration: none;
}

@media (min-width: 50em) {
    .locationbox__columnwrapper1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 1em;
    }
}

.locationbox__column--textbox {
    // background-color: #f8f9fc;
    background-size: cover;
    background-position: center;
}

.locationbox__column--map,
.locationbox__column--textbox {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.locationbox__list {
    padding: 1.5em;
}

.locationbox__listitem {
    border-left-color: rgb(106, 99, 87);
}

.locationbox__listitem {
    border-left: 2px solid #666;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: calc(2em - 1px);
    margin-left: 2.5em;
}

.locationbox__listicon {
    color: rgb(255, 65, 75);
}

.locationbox__listicon {
    color: #c7000b;
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin-right: 2em;
    margin-left: -4.5em;
}
</style>
