<script setup>
const { locale: { value: localCode } } = useI18n();
const localePath = useLocalePath();

const { data: agenda } = await useAsyncData(() => queryContent('agenda').where({ _locale: localCode }).findOne());
</script>

<template>
    <table>
        <thead>
            <tr>
                <th>{{ agenda.head.time }}</th>
                <th>{{ agenda.head.event }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in agenda.data">
                <td>{{ item.time }}</td>
                <td v-html="item.agenda"></td>
            </tr>
        </tbody>
    </table>
</template>

<style lang="scss" scoped>
table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

table td:nth-child(1) {
    width: 100px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
}

thead {
    background-color: lightgrey;
}

thead tr th:first-child,
tbody tr td:first-child {
    width: 8em;
    min-width: 8em;
    max-width: 8em;
}

th {
    padding: 12px;
    text-align: left;
}

td {
    padding: 12px;
    vertical-align: top;
}
</style>
