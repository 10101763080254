<script setup>
import { useForm } from 'vee-validate';
import * as yup from 'yup';

const { getAuthentification, setAuthentification } = useAuthentification(); // Composable Authentifizierung
const { checkHashes, createHash } = useHash(); // Composable Hashvergleich

const { t: $t } = useI18n();
const localePath = useLocalePath();

const toRoute = ref();
const toast = useToast();

// Translations
const noUserFound = ref($t('errors.messages.noUserFound'));
const loginButtonLabel = ref();


// Options
const loadingLogin = ref(false);

const showNoUserFound = () => {
    toast.add({ severity: 'warn', summary: 'Warnung', detail: noUserFound.value, life: 3000 });
};

// Validation
const schemaLoginValidation = yup.object({
    username: yup.string().required($t('form.default.required')).label(),
    password: yup.string().required($t('form.default.required')).label()
});

const { defineField, handleSubmit, resetForm, errors, isSubmitting } = useForm({
    validationSchema: schemaLoginValidation,
    initialValues: {
        username: 'fusionsolar',
        password: 'event',
    }
});

const [username] = defineField('username');
const [password] = defineField('password');

onMounted(() => {
    const { enabledGallery } = useRuntimeConfig().public;
    if (enabledGallery) {
        loginButtonLabel.value = $t('buttons.toGallery');
        toRoute.value = 'gallery';
    } else {
        loginButtonLabel.value = $t('buttons.login');
        toRoute.value = 'registration';
    }
})

const onSubmit = handleSubmit(async (values) => {
    loadingLogin.value = true;
    //console.log('Submitted with', JSON.stringify(values, null, 2));
    const equalHashes = await checkHashes(values);

    // Hash erzeugen
    // Auch online möglich: https://tools.keycdn.com/sha256-online-generator
    //const createdHash = await createHash('hier den zusammengesetzten string, z.B. fusionsolaruserfusionsolarpassword');
    //console.log(createdHash);


    if (equalHashes) {
        setAuthentification(true)
    } else {
        setAuthentification(false)
    }

    const isAuthenticated = getAuthentification();

    if (isAuthenticated) {
        loadingLogin.value = false;
        //await navigateTo(localePath(toRoute.value));
        await navigateTo(localePath({ name: toRoute.value, hash: '#content' }));
    } else {
        showNoUserFound();
        setTimeout(() => {
            loadingLogin.value = false;
        }, 3000);
    }
});

</script>

<template>
    <div>
        <article class="prose">
            <DisplayText text="index-with-login" />
        </article>
        <form @submit="onSubmit">
            <div class="grid md:grid-cols-2 gap-4">
                <div class="">
                    <label data-required="true" for="username">{{ $t('fields.login.username')
                        }} (fusionsolar)</label>
                    <InputText type="text" v-model="username" name="username" :class="{ 'p-invalid': errors.username }"
                        class="w-full" />
                    <small id="username-help" class="p-error">{{ errors.username }}</small>
                </div>
                <div class="">
                    <label data-required="true" for="password">{{ $t('fields.login.password')
                        }} (event)</label>
                    <InputText type="password" v-model="password" name="password"
                        :class="{ 'p-invalid': errors.password }" autocomplete="off" class="w-full" />
                    <small id="password-help" class="p-error">{{ errors.password }}</small>
                </div>
            </div>
            <div class="flex mt-4">
                <Button :label="loginButtonLabel" type="submit" :loading="loadingLogin" :disabled="isSubmitting"
                    class="flex-grow-1 md:flex-grow-0" />
            </div>
        </form>
    </div>

    <!--<pre>{{ errors }}</pre>-->
</template>

<style scoped>
.p-message-enter-active,
.p-message-leave-active {
    transition: all 1s ease;
}

.p-message-enter-from,
.p-message-leave-to {
    opacity: 0;
}

label {
    display: block;
    margin-top: 20px;
    margin-bottom: 4px;
}

[data-required="true"]::after {
    content: " (*)";
    /*color: red;*/
}

.p-invalid {
    border: 2px solid red !important;
    background-color: #fce4e4;
}

.p-error {
    display: block;
    color: red;
}

.p-toast {
    max-width: calc(100vw - 40px);
}
</style>
