<script setup>
const { t: $t } = useI18n();
</script>

<template>
    <div class="flex flex-row mt-4">
        <div class="flex align-items-center justify-content-center w-full h-4rem box">
            {{ $t('event.hasEnded') }}</div>
    </div>
</template>

<style scoped>
.box {
    background-color: red;
    color: white;
}
</style>
