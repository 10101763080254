<script setup>
const sessionData = useSessionData()
const { defaultLocale } = useI18n()

const { loginRequired, dateEventEnd } = useRuntimeConfig().public;
const isLoginRequired = loginRequired;

const eventEnded = ref();
const contingentFull = ref();

const showContingentEnded = computed(() => {
    return ((!eventEnded.value) && (contingentFull.value)) ? true : false;
})

const showEventEnded = computed(() => {
    return ((eventEnded.value) && (!contingentFull.value)) ? true : false;
})

const showForm = computed(() => {
    return ((eventEnded.value) || (contingentFull.value)) ? false : true;
})

definePageMeta({
    title: 'page.meta.title.index',
});

// Komponententausch
const FormLogin = resolveComponent('FormLogin');
const FormButton = resolveComponent('FormButton');

onMounted(async () => {

    // User kann nur über die index-Seite auf Registration-Seite. Ein direkter Aufruf der Registration-Seite ist nicht möglich
    sessionData.value.isPageAccessAllowed = true;

    try {

        // check event end
        eventEnded.value = await checkEventEnd(dateEventEnd).then((response) => response)
        //console.log('Event beendet?', eventEnded.value)
        sessionData.value.eventEnded = eventEnded.value

        // check contingent
        contingentFull.value = await checkContingent(defaultLocale).then((response) => response)
        //console.log('Kontingent voll?', contingentFull.value)
        sessionData.value.contingentFull = contingentFull.value

    } catch (error) {
        console.error(error)
    }
})
</script>

<template>
    <div class="mx-2 xl:mx-0">
        <ClientOnly>
            <TestComp />
        </ClientOnly>
        <div v-if="showContingentEnded">
            <FormContingent />
        </div>
        <div v-if="showEventEnded">
            <FormEnded />
        </div>
        <section id="index-intro">
            <article class="prose">
                <DisplayText text="index-copy" />
            </article>
        </section>
        <AppSpacer />
        <section id="index-date-location">
            <article class="prose">
                <DisplayText text="event" />
            </article>
            <AppEvent />
        </section>
        <AppSpacer />
        <section id="agenda">
            <AppAgenda />
        </section>
        <div v-if="showForm">
            <AppSpacer />
            <!-- <div v-if="isLoginRequired">
                <DisplayText text="index-with-login" />
            </div>
            <div v-else>
                <DisplayText text="index-without-login" />
            </div> -->
            <!-- <ClientOnly fallback-tag="span" fallback="Loading dialog..."> -->
            <div>
                <component :is="isLoginRequired ? FormLogin : FormButton">
                </component>
            </div>
            <!-- </ClientOnly> -->
        </div>
    </div>
</template>

<style scoped></style>
