<script setup>

</script>

<template>
    <section class="text-gray-600 body-font">
        <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div
                class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                <section>
                    <BoxLocation />
                    <div style="margin-bottom: 3rem;"></div>
                    <BoxDate />
                </section>
            </div>
            <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                <!-- <img class="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600"> -->
                <img class="object-cover object-center rounded" src="/images/location-map.jpg" alt="Image" />
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.wrapper_style {
    background-color: #f8f9fc;
}
</style>
