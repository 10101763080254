<script setup>
const { t: $t } = useI18n();
const hostEventDate = ref($t('host.eventDate'));
</script>

<template>

    <div class="locationbox__columnwrapper">
        <div class="locationbox__column locationbox__column--textbox">
            <ul>
                <li class="locationbox__listitem locationbox__listitem--location">
                    <span class="locationbox__listicon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z">
                            </path>
                        </svg>
                    </span>
                    <span>
                        <span v-html="hostEventDate"></span><br><br>
                        <a target="_blank" class="link" href="/download/fusionsolar-night-2024-de.ics">{{
                            $t('host.eventCalendar') }}</a>
                    </span>
                </li>
            </ul>
        </div>
    </div>

</template>

<style lang="scss" scoped>
.link {
    color: #c7000d;
    text-decoration: none;
}

@media (min-width: 50em) {
    .locationbox__columnwrapper1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 1em;
    }
}

.locationbox__column--textbox {
    background-size: cover;
    background-position: center;
}

.locationbox__column--map,
.locationbox__column--textbox {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.locationbox__list {
    padding: 1.5em;
}

.locationbox__listitem {
    border-left-color: rgb(106, 99, 87);
}

.locationbox__listitem {
    border-left: 2px solid #666;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: calc(2em - 1px);
    margin-left: 2.5em;
}

.locationbox__listicon {
    color: rgb(255, 65, 75);
}

.locationbox__listicon {
    color: #c7000b;
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin-right: 2em;
    margin-left: -4.5em;
}
</style>
